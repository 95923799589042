<template>
  <v-container id="physical-evaluation" fluid tag="section" class="container-full-size">
    <v-col cols="12">
      <div class="row-flex-align-space">
        <div>
          <v-btn color="default" class="mr-0" @click="back()">
            {{ str['back'] }}
          </v-btn>
          <v-btn
            v-if="
              physicalEvaluation &&
              physicalEvaluation.status !== 1 &&
              employeePhysicalEvaluationStatus !== 1 &&
              physicalEvaluation.status !== 4 &&
              (showValidateProgressStatus || (!showValidateProgressStatus && physicalEvaluation.status !== 3)) &&
              validateButtonStr
            "
            color="success"
            class="mr-0 ml-3"
            @click="validatePhysicalEvaluation()"
          >
            {{ validateButtonStr }}
          </v-btn>
          <v-btn v-if="detailsToEdit && detailsToEdit.length" color="blue" class="mr-0 ml-3" @click="openEditDetails()">
            {{ str['edit_data'] }}
          </v-btn>
          <v-btn v-if="showPayment" color="warning" class="mr-0 ml-3" @click="openPayment()">
            {{ str['payment'] }}
          </v-btn>
          <v-btn
            v-if="showResetClientData && showResetClientData.types && showResetClientData.types.indexOf(physicalEvaluation.type) > -1"
            color="error"
            class="mr-0 ml-3"
            @click="resetClientData()"
          >
            {{ str['reset_client_data'] }}
          </v-btn>
          <span v-if="showClientBirthday && isClientBirthday" class="warning--text ml-2">{{ str['birthday_person'] }} 🎉</span>
        </div>
        <div>
          <v-btn
            v-if="showChangeProgressStatus && physicalEvaluation.status !== physicalEvaluationStatusDict.progressing"
            color="warning"
            class="mr-0 ml-3"
            @click="changeToProgressStatus()"
          >
            {{ str['progressing'] }}
          </v-btn>
          <v-btn v-if="showDelete" color="error" class="mr-0 ml-3" @click="deletePhysicalEvaluation()">
            {{ str['delete'] }}
          </v-btn>
        </div>
      </div>
    </v-col>
    <div v-if="showAutoScrollButtons" class="footer-buttons-fixed">
      <v-btn class="mr-1" @click="goToAccordion('questions')">
        {{ str['questions'] }}
      </v-btn>
      <v-btn v-if="trainingPlanInfo" class="mr-1" @click="goToAccordion('training_plan')">
        {{ str['training'] }}
      </v-btn>
      <v-btn v-if="foodPlanInfo" class="mr-0" @click="goToAccordion('food_plan')">
        {{ str['nutrition'] }}
      </v-btn>
    </div>
    <v-col v-if="showDiary" cols="12" class="pb-1">
      <v-tabs
        v-model="typeTabActive"
        background-color="transparent"
        color="secondary"
        style="margin-top: 0"
        grow
        show-arrows
        @change="changeTypeTab"
      >
        <v-tab v-for="tab in typesTabs" :key="tab.value">
          {{ str[tab.tab] ? str[tab.tab] : tab.tab }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-card v-if="!showDiary || (typesTabs[typeTabActive] && typesTabs[typeTabActive].status !== 'diary')" style="margin-top: 0">
      <v-card-text>
        <v-col
          v-if="(showFeedbackReply || showSendFeedback) && (physicalEvaluation.status === 1 || !showSendFeedbackAfterValidation)"
          cols="12"
        >
          <v-btn color="success" class="mr-0" style="min-width: 250px" @click="openFeedbackMessage()">
            {{ showSendFeedback ? str['send_feedback'] : str['send_message'] }}
          </v-btn>
        </v-col>
        <v-list-item>
          <v-list-item-content style="margin: 0 auto; max-width: 400px">
            <v-list-item-title v-for="(detail, index) in details" :key="index" cols="12" style="margin: 5px 0">
              <span v-if="detail.parent" class="label-parent">
                {{ detail.parent }}
              </span>
              <span
                v-if="detail.type !== 'video'"
                class="font-weight-black"
                style="display: inline-block; margin-right: 5px; min-width: 45px"
              >
                {{ detail.title }}:
              </span>
              <span v-if="detail.type !== 'video'" class="font-weight-regular" style="white-space: break-spaces">
                {{ detail.value }}
              </span>
              <div v-if="detail.type === 'video' && detail.value" class="font-weight-black" style="margin: 15px 0 10px">
                {{ detail.title }}
              </div>
              <div v-if="detail.type === 'video' && detail.value">
                <video
                  :src="detail.value && isNaN(detail.value) ? detail.value : ''"
                  style="max-width: 80%; max-height: 500px"
                  controls
                ></video>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <div v-if="videos && videos.length" class="text-center">
        <h3 class="text-center mb-2">
          {{ str['videos'] }}
        </h3>
        <div>
          <div v-for="(videoItem, index) in videos" :key="index" style="padding: 10px 0 20px; position: relative">
            <video :src="videoItem" style="max-width: 80%; max-height: 500px" controls></video>
            <div class="icon-photo-download">
              <i class="mdi mdi-tray-arrow-down" style="font-size: 1.2em" @click="downloadVideo(videoItem)" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="(!showOthersFeedbacks || !feedbacksList.length) && !hidePhotos && photos && photos.length">
        <h3 class="text-center mb-2">
          {{ str['photos'] }}
        </h3>
        <v-carousel v-if="photos.length" v-model="currentPhotoIndex" style="margin-bottom: 20px" class="custom-loading-background">
          <v-carousel-item
            v-for="(photo, index) in photos"
            :key="index"
            :src="photo.photo"
            contain
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <div class="icon-photo-download row-flex-align">
              <div v-if="showUpdateImage" class="icon-image-field cursor-hover mr-2">
                <i class="mdi mdi-file-replace" style="font-size: 1em" />
                <input id="phyeval-input-photos" type="file" accept=".png,.jpg,.jpeg" @change="updatePhotos" />
              </div>
              <i class="mdi mdi-tray-arrow-down" style="font-size: 1.2em" @click="downloadImage(photo.photo)" />
              <i class="mdi mdi-trash-can-outline ml-2 error--text" style="font-size: 1.2em" @click="deleteImage(photo)" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div
        v-if="
          showEvolutionChart &&
          physicalEvaluationsChart &&
          physicalEvaluationsChart.data &&
          physicalEvaluationsChart.data.fields &&
          physicalEvaluationsChart.options
        "
        class="chart-container"
      >
        <base-material-chart-card
          :data="physicalEvaluationsChart.data"
          :options="physicalEvaluationsChart.options"
          color="blue-grey darken-3"
          hover-reveal
          type="Line"
          :shadow="false"
          :event-handlers="physicalEvaluationsChartEventHandlers"
        >
          <div class="custom-chart-legend">
            <div v-for="(itemVal, indexVal) in physicalEvaluationsChart.data.fields" :key="indexVal">
              <span :style="{ backgroundColor: itemVal.color }"></span>
              {{ itemVal.name }}
            </div>
          </div>
        </base-material-chart-card>
      </div>
      <div v-if="showOthersFeedbacks && feedbacksList.length">
        <div class="pl-4 pr-4">
          <v-autocomplete
            v-model="othersFeedbacksFilter.value"
            :label="str['filter']"
            item-text="name"
            item-value="id"
            :items="feedbacksList"
            :no-data-text="str['no_data']"
            multiple
            chips
            small-chips
            closable-chips
            clearable
            outlined
            :search-input.sync="othersFeedbacksFilter.search"
            @change="othersFeedbacksFilter.search = ''"
          />
        </div>
        <div class="row-flex-responsive custom-scroll-x">
          <div
            v-for="(item, itemIndex) in feedbacksListFiltered"
            :key="itemIndex"
            class="pr-2 pl-2"
            style="width: 100%; min-width: 400px; position: relative"
          >
            <div class="row-align-center pb-1">
              <div>
                <h3 class="text-center">
                  {{ item.name }}
                </h3>
                <h4 class="text-center mb-2">{{ item.weight }} {{ str['kg'] }}</h4>
              </div>
              <div class="float-right">
                <div v-if="itemIndex > 0">
                  <v-icon color="blue" class="cursor-hover" style="font-size: 22px" @click.stop="sortFeedbackList(item, 'left')">
                    mdi-arrow-left-bold-circle
                  </v-icon>
                </div>
                <div v-if="feedbacksListFiltered.length - 1 !== itemIndex" :class="{ 'mt-1': itemIndex > 0 }">
                  <v-icon color="blue" class="cursor-hover" style="font-size: 22px" @click.stop="sortFeedbackList(item, 'right')">
                    mdi-arrow-right-bold-circle
                  </v-icon>
                </div>
              </div>
            </div>
            <div v-if="!item.photos.length" style="min-height: 500px; position: relative">
              <div v-if="!item.loading" class="custom-loading custom-loading-background custom-loading-radius">
                <v-btn color="blue" @click="getPhotos(item)">
                  {{ str['show_photos'] }}
                </v-btn>
              </div>
              <div v-if="item.loading" class="custom-loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <v-carousel
              v-if="item.photos.length"
              v-model="item.currentPhoto"
              style="margin-bottom: 20px"
              class="custom-loading-background custom-loading-radius"
            >
              <v-carousel-item
                v-for="(photo, index) in item.photos"
                :key="index"
                :src="photo.photo"
                contain
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <div class="icon-photo-download pt-0">
                  <div v-if="showUpdateImage && item.current" class="icon-image-field cursor-hover">
                    <i class="mdi mdi-file-replace" style="font-size: 1em" />
                    <input id="phyeval-input-photos" type="file" accept=".png,.jpg,.jpeg" @change="updatePhotos" />
                  </div>
                  <div v-if="photo.photo">
                    <i class="mdi mdi-tray-arrow-down" style="font-size: 1.2em" @click="downloadImage(photo.photo)" />
                  </div>
                  <div v-if="item.current && photo.photo">
                    <i class="mdi mdi-trash-can-outline error--text" style="font-size: 1.2em" @click="deleteImage(photo)" />
                  </div>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </div>
      <v-expansion-panels v-model="accordionsOpened" multiple>
        <template v-for="(item, index) in accordion">
          <v-expansion-panel
            v-if="
              (item.id === 'training_plan' && trainingPlanInfo) ||
              (item.id === 'food_plan' && foodPlanInfo) ||
              (item.id !== 'training_plan' && item.id !== 'food_plan')
            "
            :id="'accordion-' + item.id"
            :key="index"
            style="margin: 1px"
            @change="changeAccordion(item)"
          >
            <!-- Fixed questions - start -->
            <div v-if="showFixedQuestions && item.id === 'questions' && fixedQuestionsVisible" class="accordion-fixed-top custom-scroll">
              <div
                v-for="(itemData, itemIndex) in item.data"
                :key="itemIndex"
                cols="12"
                style="margin: 2px 0; white-space: initial"
                :class="[itemData.color ? itemData.color + '--text' : '']"
              >
                <div
                  v-if="itemData.parent"
                  class="font-weight-black"
                  style="margin: 10px 0 7px; font-size: 16px; text-decoration: underline"
                >
                  {{ itemData.parent }}
                </div>
                <div>
                  <span v-if="itemData.title" class="font-weight-black">
                    {{ itemData.title }}{{ itemData.title.indexOf(':') === -1 && itemData.title.indexOf('?') === -1 ? ':' : '' }}
                  </span>
                  <div
                    v-if="itemData.value && typeof itemData.value === 'string' && itemData.value.indexOf('data:audio') > -1"
                    class="mt-2"
                  >
                    <audio controls>
                      <source :src="itemData.value" type="audio/mp3" />
                    </audio>
                  </div>
                  <span v-else class="font-weight-regular">
                    <span
                      v-if="itemData.value && typeof itemData.value === 'string' && itemData.value.includes(', ') && !str[itemData.value]"
                      class="list-commas"
                    >
                      <span v-for="(subItem, subIndex) in itemData.value.split(', ')" :key="subIndex">
                        {{ str[subItem] ? str[subItem] : subItem }}
                      </span>
                    </span>
                    <span v-else>
                      {{ str[itemData.value] ? str[itemData.value] : itemData.value }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <!-- Fixed questions - end -->
            <v-expansion-panel-header>
              <div class="row-flex-align">
                <div>
                  {{ item.title }}
                </div>
                <div v-if="item.id === 'training_plan' && trainingPlanInfo" class="ml-1">
                  <div
                    v-if="
                      user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_dates
                    "
                  >
                    -
                    {{ trainingPlanInfo.start_date + ' / ' + trainingPlanInfo.end_date }}
                  </div>
                  <div
                    v-if="
                      user && user.configurations && user.configurations.training_plans && !user.configurations.training_plans.show_dates
                    "
                  >
                    - {{ str['started_in'] }} {{ trainingPlanInfo.start_date }}
                  </div>
                </div>
                <div v-if="item.id === 'food_plan' && foodPlanInfo" class="ml-1">- {{ foodPlanInfo.date }}</div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text :class="{ 'pt-0 pb-0 pl-0 pr-0': !item.data }">
                <v-list-item :class="{ 'pt-0 pb-0 pl-0 pr-0': !item.data }">
                  <v-list-item-content v-if="item.data">
                    <v-list-item-title
                      v-for="(itemData, itemIndex) in item.data"
                      :key="itemIndex"
                      cols="12"
                      style="margin: 5px 0; white-space: initial"
                      :class="[itemData.color ? itemData.color + '--text' : '']"
                    >
                      <div
                        v-if="itemData.parent"
                        class="font-weight-black"
                        style="margin: 10px 0 7px; font-size: 16px; text-decoration: underline"
                      >
                        {{ itemData.parent }}
                      </div>
                      <div>
                        <span v-if="itemData.title" class="font-weight-black" style="margin-right: 5px">
                          {{ itemData.title }}{{ itemData.title.indexOf(':') === -1 && itemData.title.indexOf('?') === -1 ? ':' : '' }}
                        </span>
                        <div
                          v-if="itemData.value && typeof itemData.value === 'string' && itemData.value.indexOf('data:audio') > -1"
                          class="mt-2"
                        >
                          <audio controls>
                            <source :src="itemData.value" type="audio/mp3" />
                          </audio>
                        </div>
                        <span v-else class="font-weight-regular">
                          <span
                            v-if="
                              itemData.value && typeof itemData.value === 'string' && itemData.value.includes(', ') && !str[itemData.value]
                            "
                            class="list-commas"
                          >
                            <span v-for="(subItem, subIndex) in itemData.value.split(', ')" :key="subIndex">
                              {{ str[subItem] ? str[subItem] : subItem }}
                            </span>
                          </span>
                          <span v-else>
                            {{ str[itemData.value] ? str[itemData.value] : itemData.value }}
                          </span>
                        </span>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-if="item.id === 'training_plan'" class="pt-0 pb-0" style="margin: -12px">
                    <training-plan
                      v-if="trainingPlan && !trainingPlanLoading"
                      ref="trainingPlanComponent"
                      :client="client"
                      :plan="trainingPlan"
                      :has-back="false"
                      :hide-delete="true"
                      :mounted-callback="trainingPlanComponentMountedCallback"
                      class="pt-0 pb-0 pl-0 pr-0"
                    />
                    <div v-if="trainingPlanLoading" class="custom-loading">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content v-if="item.id === 'food_plan'" class="pt-0 pb-0" style="margin: -12px">
                    <food-plan
                      v-if="foodPlan && !foodPlanLoading"
                      ref="foodPlanComponent"
                      :client="client"
                      :plan="foodPlan"
                      :header-fixed="false"
                      :has-back="false"
                      :hide-delete="true"
                      :mounted-callback="foodPlanComponentMountedCallback"
                      class="pt-0 pb-0 pl-0 pr-0"
                    />
                    <div v-if="foodPlanLoading" class="custom-loading">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content v-if="item.id === 'calendar'" class="pt-0 pb-0">
                    <calendar v-if="calendarOpened && !calendarLoading" :client="client" />
                    <div v-if="calendarLoading" class="custom-loading">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-card>
    <v-card v-if="showDiary && typesTabs[typeTabActive] && typesTabs[typeTabActive].status === 'diary'" style="margin-top: 0">
      <v-data-table
        :headers="diaryTable.headers"
        :items="diaryTable.body"
        :no-data-text="str['no_physical_evaluations']"
        :no-results-text="str['no_physical_evaluations']"
        class="elevation-1"
        :loading="diaryTable.loading"
        :loading-text="str['processing']"
        @click:row="openPhysicalEvaluation"
      >
        <template v-for="(header, headerIndex) in diaryTable.headers" #[`item.${header.value}`]="{ item }">
          <slot :name="[`item.${header.value}`]" :item="item">
            <div :key="headerIndex">
              <div v-if="!header.alert">
                {{ item[header.value] }}
              </div>
              <div v-if="header.alert" class="row-align-center">
                <div class="ellipsis-rows-1">
                  {{ item[header.value] }}
                </div>
                <v-icon color="info" dark class="cursor-hover ml-1" @click.stop="showInformation(item[header.value])">
                  mdi-information
                </v-icon>
              </div>
            </div>
          </slot>
        </template>
        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogFeedbackMessage" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['send_message'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-textarea v-model="feedbackMessage" :label="str['message']" rows="5" hide-details />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogFeedbackMessage = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" :disabled="!feedbackMessage && !showSendFeedback" style="margin-right: 0" @click="sendFeedbackMessage()">
            {{ str['send'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="detailsToEdit" v-model="dialogEditDetails" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['edit_data'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="item in detailsToEdit" :key="item.field" cols="12">
                <div v-if="item.parent" class="label-parent">
                  {{ item.parent }}
                </div>
                <v-text-field
                  v-if="item.type !== 'textarea' && item.type !== 'datepicker'"
                  v-model="item.value"
                  :label="item.label"
                  hide-details
                />
                <v-textarea v-if="item.type === 'textarea'" v-model="item.value" :label="item.label" rows="3" hide-details />
                <v-menu
                  v-if="item.type === 'datepicker'"
                  v-model="item.opened"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="item.value" :label="item.label" readonly v-bind="attrs" hide-details v-on="on" />
                  </template>
                  <v-date-picker v-model="item.value" style="margin: 0" :locale="datepickerLanguage" @change="item.opened = false" />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeEditDetails">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="saveEditDetails">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="hasChatReply" v-model="dialogChatReply" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['edit_data'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="chatReplyMessage" :label="str['message']" rows="5" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeChatReply">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="sendChatReply">
            {{ str['send'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="physicalEvaluationOpened && client" v-model="dialogPhysicalEvaluation" persistent max-width="750px">
      <v-card>
        <v-card-text>
          <v-container>
            <div v-if="physicalEvaluationLoading" class="custom-loading-text">
              {{ str['processing'] }}
            </div>
            <iframe
              :style="{ opacity: physicalEvaluationLoading ? '0' : '1' }"
              :src="
                'https://physical-evaluation.mkgest.com/?pt_id=' +
                ptId +
                '&client_id=' +
                client.id +
                '&pe_id=' +
                physicalEvaluationOpened.id +
                (physicalEvaluationOpened.type ? '&type=' + physicalEvaluationOpened.type : '') +
                '&dashboard=true' +
                linkParameters
              "
              :name="Date.now()"
              style="width: 100%; height: calc(100vh - 15px); border: none"
              allow="geolocation; microphone; camera; notifications;"
            ></iframe>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closePhysicalEvaluation()">
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogPayment && paymentData" v-model="dialogPayment" persistent max-width="750px">
      <v-card>
        <div class="card-body-scroll custom-scroll">
          <client-payment :payment="paymentData" :page="false" />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closePayment()">
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPayday" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['update_payment_date'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-menu
            v-model="datepickerPaydayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field v-model="payday" :label="str['payment_date']" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="payday" style="margin: 0" :locale="datepickerLanguage" @change="datepickerPaydayMenu = false" />
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogPayday = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="updatePayday(payday)">
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="addFoodPlanOptionAfterValidation" v-model="addFoodPlanOptionProcessing" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <h5>
            {{ str['nutrition_plan_add_option_processing'] }}
          </h5>
        </v-card-title>
        <v-card-text>
          <div class="custom-loading-container">
            <div class="custom-loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div v-if="showResetClientData && resetClientDataProcessing" style="display: none">
      <iframe
        :src="
          'https://new-physical-evaluation.mkgest.com?pt_id=' +
          client.pt_id +
          '&client_id=' +
          client.id +
          '&get_data_callback=' +
          physicalEvaluation.id +
          '&type=' +
          physicalEvaluation.type +
          '&&timestamp=' +
          new Date().getTime()
        "
        :name="Date.now()"
        allow="geolocation; microphone; camera; notifications;"
      ></iframe>
    </div>

    <client-feedback
      v-if="updateFeedbackDateAfterValidation"
      ref="feedback"
      :client="client"
      :mounted-callback="clientFeedbackMounted"
      :destroy-callback="clientFeedbackDestroy"
    />
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import ChatApi from '@/services/ChatApi'
import StorageApi from '@/services/StorageApi'
import Utils from '@/services/Utils'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    TrainingPlan: () => import('@/components/dashboard/trainingPlan/TrainingPlan'),
    FoodPlan: () => import('@/components/dashboard/foodPlan/FoodPlan'),
    Calendar: () => import('@/components/dashboard/calendar/Calendar'),
    ClientPayment: () => import('@/components/dashboard/client/Payment'),
    ClientFeedback: () => import('@/components/dashboard/client/Feedback'),
  },
  beforeRouteLeave: function (to, from, next) {
    const self = this
    this.trainingPlanDestroyValidation(function (trainingPlanSuccess) {
      if (trainingPlanSuccess) {
        self.foodPlanDestroyValidation(function (foodPlanSuccess) {
          if (foodPlanSuccess) {
            next()
          } else {
            next(false)
          }
        })
      } else {
        next(false)
      }
    })
  },
  data() {
    const physicalEvaluationCache = Utils.getStorage('physicalEvaluation')
    const physicalEvaluation = physicalEvaluationCache ? physicalEvaluationCache : {}
    const physicalEvaluationsListCache = Utils.getStorage('physicalEvaluationsList')
    const physicalEvaluationsList = physicalEvaluationsListCache ? physicalEvaluationsListCache : null
    const physicalEvaluationsChartCache = Utils.getStorage('physicalEvaluationsChart')
    const physicalEvaluationsChart = physicalEvaluationsChartCache ? physicalEvaluationsChartCache : null
    const client = Utils.getStorage('client')
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const isInitialQuiz = Utils.getFeedbackTypesDict().initial_quiz === physicalEvaluation.type ? true : false
    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      client: client ? client : {},
      ptId: axios.defaults.ptId,
      physicalEvaluation: physicalEvaluation,
      showFeedbackReply: config && config.feedback && config.feedback.show_feedback_reply ? true : false,
      showSendFeedback: config && config.feedback && config.feedback.show_send_feedback ? true : false,
      showSendFeedbackAfterValidation: config && config.feedback && config.feedback.show_send_feedback_after_validation ? true : false,
      matchMeasurementsPerimeters: config && config.feedback && config.feedback.match_measurements_perimeters ? true : false,
      openChatAfterValidation:
        config && config.feedback && config.feedback.open_chat_after_validation && Utils.hasPermission('chat_view') ? true : false,
      openPaydayAfterValidation:
        config && config.feedback && config.feedback.open_payday_after_validation && Utils.hasPermission('payday_edit') ? true : false,
      updateFeedbackDateAfterValidation: config && config.feedback && config.feedback.update_feedback_day_after_validation ? true : false,
      showUpdateImage: config && config.feedback && config.feedback.show_update_image ? true : false,
      hasChatReply: config && config.feedback && config.feedback.has_chat_reply ? true : false,
      showDiary: config && config.feedback && config.feedback.show_diary && !isInitialQuiz ? true : false,
      showOthersFeedbacks: config && config.feedback && config.feedback.show_others_feedbacks ? true : false,
      showDelete: config && config.feedback && config.feedback.show_delete && Utils.hasPermission('feedbacks_delete') ? true : false,
      validateKeepPage: config && config.feedback && config.feedback.validate_keep_page ? true : false,
      showNotes: config && config.feedback && config.feedback.show_notes ? true : false,
      showDiaryList: config && config.feedback && config.feedback.show_diary_list && !isInitialQuiz ? true : false,
      showEvolutionChart: config && config.feedback && config.feedback.show_chart_inside ? true : false,
      showPayment: config && config.feedback && config.feedback.show_initial_quiz_payment && isInitialQuiz ? true : false,
      showClientBirthday: config && config.feedback && config.feedback.show_client_birthday ? true : false,
      showUpdateDate: config && config.feedback && config.feedback.show_update_date ? true : false,
      hidePhotos: config && config.feedback && config.feedback.hide_photos ? true : false,
      showTypeName: config && config.feedback && config.feedback.show_type_name ? true : false,
      showValidateProgressStatus: config && config.feedback && config.feedback.show_validate_progress_status ? true : false,
      showNutritionGoal: config && config.feedback && config.feedback.show_nutrition_goal ? true : false,
      showFixedQuestions: config && config.feedback && config.feedback.show_fixed_questions ? true : false,
      showAutoScrollButtons: config && config.feedback && config.feedback.show_auto_scroll_buttons ? true : false,
      totalPhotos: config && config.feedback && config.feedback.total_photos ? config.feedback.total_photos : 3,
      addFoodPlanOptionAfterValidation: config && config.feedback && config.feedback.add_food_plan_option_after_validation ? true : false,
      refreshClientAfterValidation: config && config.feedback && config.feedback.refresh_client_after_validation ? true : false,
      showResetClientData:
        config && config.feedback && config.feedback.show_reset_client_data ? config.feedback.show_reset_client_data : false,
      dangerQuestions: config && config.feedback && config.feedback.danger_questions ? config.feedback.danger_questions : false,
      showChangeProgressStatus: config && config.feedback && config.feedback.change_progress_status ? true : false,
      isInitialQuiz: isInitialQuiz,
      isDefaultType: Utils.getFeedbackTypesDict().default === physicalEvaluation.type ? true : false,
      physicalEvaluationsList: physicalEvaluationsList,
      physicalEvaluationsChart: physicalEvaluationsChart,
      linkParameters: window.jsonAdmin && window.jsonAdmin.linkParameters ? window.jsonAdmin.linkParameters : '',
      feedbacksList: [],
      othersFeedbacksFilter: {
        search: '',
        value: [],
      },
      details: [],
      detailsToEdit: [],
      dialogEditDetails: false,
      accordion: [],
      accordionsOpened: [],
      videos: [],
      photos: [],
      currentPhotoIndex: 0,
      dialogFeedbackMessage: false,
      feedbackMessage: '',
      imageMaxSize: 700,
      dialogChatReply: false,
      chatReplyMessage: null,
      typesTabs: Utils.getFeedbackTypes(),
      typeTabActive: 0,
      diaryTable: {
        ready: false,
        loading: false,
        headers: [],
        body: [],
      },
      foodPlan: null,
      foodPlanInfo: null,
      trainingPlan: null,
      trainingPlanInfo: null,
      calendarOpened: false,
      foodPlanLoading: false,
      trainingPlanLoading: false,
      calendarLoading: false,
      dialogPhysicalEvaluation: false,
      physicalEvaluationOpened: null,
      physicalEvaluationLoading: false,
      dialogPayment: false,
      paymentData: null,
      dialogPayday: false,
      payday: null,
      datepickerPaydayMenu: null,
      isClientBirthday:
        client && client.birth_date && moment().format('MM-DD') === moment(client.birth_date).format('MM-DD') ? true : false,
      validateButtonStr: '',
      fixedQuestionsVisible: false,
      foodPlanComponentMountedCallback: null,
      addFoodPlanOptionProcessing: false,
      trainingPlanComponentMountedCallback: null,
      resetClientDataProcessing: false,
      feedbackRef: null,
      employeePhysicalEvaluationStatus: null,
      physicalEvaluationsChartEventHandlers: this.getPhysicalEvaluationsChartEventHandlers(),
      physicalEvaluationStatusDict: this.getPhysicalEvaluationStatusDict(),
    }
  },
  computed: {
    feedbacksListFiltered() {
      const self = this
      return this.feedbacksList
        .filter(function (item) {
          return self.othersFeedbacksFilter.value.includes(item.id)
        })
        .sort(function (a, b) {
          return self.othersFeedbacksFilter.value.indexOf(a.id) - self.othersFeedbacksFilter.value.indexOf(b.id)
        })
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getPhysicalEvaluation()
    this.getEmployeePhysicalEvaluationStatus()
    this.validateRefreshPlans()
    this.activeFixedQuestions()
  },
  beforeDestroy: function () {
    this.removeEventListener()
    Utils.removeStorage('physicalEvaluationsList')
    Utils.removeStorage('physicalEvaluationsChart')
  },
  methods: {
    back: function () {
      this.$router.goBack('/home/client')
    },
    getPhysicalEvaluationStatusDict() {
      const dict = {}
      const list = Utils.getPhysicalEvaluationStatus()
      if (list?.length) {
        list.forEach(function (item) {
          dict[item.status] = item.value
        })
      }
      return dict
    },
    getPhysicalEvaluation: function (updated, callback) {
      const self = this
      Api.getPhysicalEvaluation(
        {
          client_id: this.client.dbId,
          id: this.physicalEvaluation.id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.physicalEvaluation = response.data[0]
            if (updated) {
              self.setDetails()
              self.getFeedbacksList(true)
            } else {
              self.setData()
            }
            if (callback) {
              callback()
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getEmployeePhysicalEvaluationStatus() {
      try {
        if (
          this.client.employee1 &&
          this.client.employee2 &&
          ((this.client.employee1 === this.user.id && this.client.flags & (1 << 3)) ||
            (this.client.employee2 === this.user.id && this.client.flags & (1 << 4)))
        ) {
          const list = Utils.getPhysicalEvaluationStatus()
          const validatedStatus = list && list.length ? list.find((l) => l.status === 'validated') : null
          if (validatedStatus) {
            this.employeePhysicalEvaluationStatus = validatedStatus.value
            return
          }
        }
      } catch {}
      this.employeePhysicalEvaluationStatus = this.physicalEvaluation.status
    },
    validateRefreshPlans: function () {
      const feedbackAlert =
        this.user && this.user.configurations && this.user.configurations.feedback && this.user.configurations.feedback.feedback_alert
          ? this.user.configurations.feedback.feedback_alert
          : null
      if (feedbackAlert === 'change_plans' && this.physicalEvaluation.status === 0 && this.physicalEvaluation.id % 2 !== 0) {
        this.$notify({
          group: 'alert',
          title: window.strings['update_plans'],
          text: window.strings['client'] + ' <b>' + this.client.name + '</b>',
          duration: 30000,
        })
      }
    },
    setData: function () {
      const self = this
      this.setDetails()
      this.getFeedbacksList()
      if (!this.hidePhotos) {
        this.getAssets(function () {
          self.setCurrentFeedbackPhotos()
        })
      }
      this.setAccordion()
    },
    setDetails: function () {
      const self = this
      const config = this.user && this.user.configurations ? this.user.configurations : {}
      const list = [
        {
          title: window.strings['date'],
          value: this.physicalEvaluation.date ? this.physicalEvaluation.date : '',
        },
      ]
      const toEdit = []
      const typeConfig =
        config && config.feedback && config.feedback.types
          ? config.feedback.types.find(function (it) {
              return it.type === self.physicalEvaluation.type
            })
          : null

      if (typeConfig && typeConfig.hide_photos) {
        this.hidePhotos = true
      }

      if (typeConfig && typeConfig.validate_button) {
        this.validateButtonStr = window.strings[typeConfig.validate_button]
          ? window.strings[typeConfig.validate_button]
          : typeConfig.validate_button
      } else {
        this.validateButtonStr = window.strings['validate_physical_evaluation']
      }

      if (this.showTypeName && typeConfig && typeConfig.name) {
        list.push({
          title: window.strings['type'],
          value: window.strings[typeConfig.name] ? window.strings[typeConfig.name] : typeConfig.name,
        })
      }

      if (this.showUpdateDate) {
        toEdit.push({
          id: 'date',
          label: window.strings['date'],
          value: this.physicalEvaluation.date,
          type: 'datepicker',
          opened: false,
        })
      }

      if (
        this.physicalEvaluation.body &&
        (this.physicalEvaluation.body.height || this.physicalEvaluation.body.height === 0) &&
        (!config.feedback ||
          (config.feedback && !config.feedback.hidden) ||
          (config.feedback && config.feedback.hidden && config.feedback.hidden.indexOf('height') === -1)) &&
        (!typeConfig ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && typeConfig.hidden && typeConfig.hidden.indexOf('height') === -1))
      ) {
        list.push({
          id: 'height',
          title: window.strings['height'],
          value: this.physicalEvaluation.body.height + ' cm',
        })
        toEdit.push({
          id: 'body',
          field: 'height',
          label: window.strings['height'],
          value: this.physicalEvaluation.body.height,
        })
      }

      if (
        this.physicalEvaluation.body &&
        (this.physicalEvaluation.body.weight || this.physicalEvaluation.body.weight === 0) &&
        (!config.feedback ||
          (config.feedback && !config.feedback.hidden) ||
          (config.feedback && config.feedback.hidden && config.feedback.hidden.indexOf('weight') === -1)) &&
        (!typeConfig ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && typeConfig.hidden && typeConfig.hidden.indexOf('weight') === -1))
      ) {
        list.push({
          id: 'weight_str',
          title: window.strings['weight_str'],
          value: this.physicalEvaluation.body.weight + ' kg',
        })
        toEdit.push({
          id: 'body',
          field: 'weight',
          label: window.strings['weight_str'],
          value: this.physicalEvaluation.body.weight,
        })
      }

      if (
        ((config.feedbacks && config.feedbacks.indexOf('questions') === -1) ||
          (config.feedback && config.feedback.questions_only_with_how_you_feel && this.physicalEvaluation.remarks.indexOf(':') === -1)) &&
        (!config.feedback ||
          (config.feedback && !config.feedback.hidden) ||
          (config.feedback && config.feedback.hidden && config.feedback.hidden.indexOf('how_you_feel') === -1)) &&
        (!typeConfig ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && !typeConfig.hidden) ||
          (typeConfig && typeConfig.hidden && typeConfig.hidden.indexOf('how_you_feel') === -1))
      ) {
        list.push({
          id: 'how_did_you_feel',
          title: window.strings['how_did_you_feel'],
          value: this.physicalEvaluation.remarks ? this.physicalEvaluation.remarks : '',
        })
      }

      try {
        if (typeConfig && typeConfig.global_fields) {
          typeConfig.global_fields.forEach(function (item) {
            if (!item.only_table && !item.only_chart) {
              const name = item.label ? (window.strings[item.label] ? window.strings[item.label] : item.label) : window.strings[item.field]
              if (item.average) {
                const averageValues = []
                item.average.forEach(function (a) {
                  if (self.physicalEvaluation[item.id] && self.physicalEvaluation[item.id][a]) {
                    averageValues.push(self.physicalEvaluation[item.id][a])
                  }
                })
                list.push({
                  id: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  title: name,
                  value: averageValues.length ? (averageValues.reduce((acc, val) => acc + val, 0) / averageValues.length).toFixed(1) : '0',
                })
              } else {
                const val =
                  self.physicalEvaluation[item.id] &&
                  (self.physicalEvaluation[item.id][item.field] || self.physicalEvaluation[item.id][item.field] === 0)
                    ? self.physicalEvaluation[item.id][item.field]
                    : ''
                list.push({
                  id: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  title: name,
                  value: val,
                })
                toEdit.push({
                  id: item.id,
                  field: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  label: name,
                  value: val,
                })
              }
            }
          })
        } else if (config.feedback && config.feedback.global_fields) {
          config.feedback.global_fields.forEach(function (item) {
            if (!item.only_table && !item.only_chart) {
              const name = item.label ? (window.strings[item.label] ? window.strings[item.label] : item.label) : window.strings[item.field]
              if (item.average) {
                const averageValues = []
                item.average.forEach(function (a) {
                  if (self.physicalEvaluation[item.id] && self.physicalEvaluation[item.id][a]) {
                    averageValues.push(self.physicalEvaluation[item.id][a])
                  }
                })
                list.push({
                  id: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  title: name,
                  value: averageValues.length ? (averageValues.reduce((acc, val) => acc + val, 0) / averageValues.length).toFixed(1) : '0',
                })
              } else {
                const val =
                  self.physicalEvaluation[item.id] &&
                  (self.physicalEvaluation[item.id][item.field] || self.physicalEvaluation[item.id][item.field] === 0)
                    ? self.physicalEvaluation[item.id][item.field]
                    : ''
                list.push({
                  id: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  title: name,
                  value: val,
                })
                toEdit.push({
                  id: item.id,
                  field: item.field,
                  parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
                  label: name,
                  value: val,
                })
              }
            }
          })
        }

        if (typeConfig && typeConfig.view_fields) {
          typeConfig.view_fields.forEach(function (item) {
            const name = item.label ? (window.strings[item.label] ? window.strings[item.label] : item.label) : window.strings[item.field]
            const val =
              self.physicalEvaluation[item.id] &&
              (self.physicalEvaluation[item.id][item.field] || self.physicalEvaluation[item.id][item.field] === 0)
                ? self.physicalEvaluation[item.id][item.field]
                : ''
            list.push({
              id: item.field,
              parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
              title: name,
              value: val,
              type: item.type,
            })
          })
        } else if (config.feedback && config.feedback.view_fields) {
          config.feedback.view_fields.forEach(function (item) {
            const name = item.label ? (window.strings[item.label] ? window.strings[item.label] : item.label) : window.strings[item.field]
            const val =
              self.physicalEvaluation[item.id] &&
              (self.physicalEvaluation[item.id][item.field] || self.physicalEvaluation[item.id][item.field] === 0)
                ? self.physicalEvaluation[item.id][item.field]
                : ''
            list.push({
              id: item.field,
              parent: item.parent ? (window.strings[item.parent] ? window.strings[item.parent] : item.parent) : null,
              title: name,
              value: val,
              type: item.type,
            })
          })
        }
      } catch (error) {}

      if (this.showNotes) {
        list.push({
          id: 'notes',
          title: window.strings['notes'],
          value: this.physicalEvaluation.notes,
        })
        toEdit.push({
          id: 'notes',
          label: window.strings['notes'],
          value: this.physicalEvaluation.notes,
          type: 'textarea',
        })
      }

      const videosIds = []
      list.forEach(function (l) {
        if (l.type === 'video' && l.value && !isNaN(l.value)) {
          videosIds.push(parseInt(l.value))
        }
      })
      if (videosIds.length) {
        this.getVideos(videosIds, true)
      }

      this.details = list
      this.detailsToEdit = toEdit
    },
    setAccordion: function () {
      const self = this
      const config = this.user && this.user.configurations ? this.user.configurations : null
      const typeConfig =
        config && config.feedback && config.feedback.types
          ? config.feedback.types.find(function (it) {
              return it.type === self.physicalEvaluation.type
            })
          : null
      this.accordion = []

      if (
        (config &&
          config.feedbacks &&
          config.feedbacks.indexOf('questions') > -1 &&
          (!config.feedback ||
            (config.feedback && !config.feedback.questions_only_with_how_you_feel) ||
            (config.feedback && config.feedback.questions_only_with_how_you_feel && this.physicalEvaluation.remarks.indexOf(':') > -1))) ||
        (typeConfig && typeConfig.accordions && typeConfig.accordions.indexOf('questions') > -1)
      ) {
        this.setQuestions()
      }

      if (config && config.feedback && config.feedback.show_accordions) {
        this.addAccordions(config.feedback.show_accordions)
      }

      if (
        (config && config.feedbacks && config.feedbacks.indexOf('body') > -1) ||
        (typeConfig && typeConfig.accordions && typeConfig.accordions.indexOf('body') > -1)
      ) {
        this.setBody()
      }

      if (
        (config && config.feedbacks && config.feedbacks.indexOf('perimeters') > -1) ||
        (typeConfig && typeConfig.accordions && typeConfig.accordions.indexOf('perimeters') > -1)
      ) {
        this.setPerimeters(typeConfig && typeConfig.perimeters_fields ? typeConfig.perimeters_fields : null)
      }

      if (
        (config && config.feedbacks && config.feedbacks.indexOf('measurements') > -1) ||
        (typeConfig && typeConfig.accordions && typeConfig.accordions.indexOf('measurements') > -1) ||
        this.matchMeasurementsPerimeters
      ) {
        this.getRegisterConfig(function (steps) {
          if (steps) {
            self.setMeasurements(steps)
          }
          self.checkAccordionsOpened()
        })
      } else {
        this.checkAccordionsOpened()
      }
    },
    getRegisterConfig: function (callback) {
      const self = this
      this.$isLoading(true)
      Api.getRegisterConfig(function (response) {
        if (response.success) {
          callback(response.data.steps)
          self.$isLoading(false)
        } else {
          callback(null)
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    setQuestions: function () {
      const remarks = this.physicalEvaluation.remarks
      let questions = []

      try {
        if (remarks) {
          questions = JSON.parse(remarks)
          for (let i = questions.length - 1; i > -1; i--) {
            if (
              this.dangerQuestions &&
              (this.dangerQuestions.includes(questions[i].id) || this.dangerQuestions.includes(questions[i].title))
            ) {
              questions[i].color = 'error'
            }
            if (questions[i].id === 'train_id') {
              this.getTrain(parseInt(questions[i].value))
              questions.splice(i, 1)
              continue
            }
            if (questions[i].parentId) {
              questions.splice(i, 1)
              continue
            }
            if (questions[i].title || (questions[i].title === '' && typeof questions[i].id === 'number')) {
              questions[i].title = questions[i].title.replace(':', '')
            } else if (questions[i].id || questions[i].id === '') {
              questions[i].title = typeof questions[i].id === 'string' ? questions[i].id.replace(':', '') : ''
              if (questions[i].answer) {
                questions[i].value = questions[i].answer
              }
            }
            if (questions[i].title && window.strings[questions[i].title]) {
              questions[i].title = window.strings[questions[i].title]
            }
            if (!questions[i].value && questions[i].answer) {
              questions[i].value = questions[i].answer
            }
            if (questions[i].value && window.strings[questions[i].value]) {
              questions[i].value = window.strings[questions[i].value]
            }
            if (questions[i].id === 'feedback_rating' && questions[i].value) {
              const feedbackRatingItems = Utils.getFeedbackRating()
              const feedbackRatingValue = feedbackRatingItems
                ? feedbackRatingItems.find(function (frItem) {
                    return frItem.value === questions[i].value
                  })
                : null
              if (feedbackRatingValue) {
                questions[i].value = feedbackRatingValue.status
              }
            }
          }
        }
      } catch (error) {
        try {
          if (remarks) {
            const remarksSplit = remarks.split('\n')
            if (remarks.indexOf('*') === -1) {
              for (let i = 0; i < remarksSplit.length; i = i + 2) {
                if (remarksSplit[i]) {
                  questions.push({
                    title: remarksSplit[i],
                    value: remarksSplit[i + 1],
                  })
                }
              }
            } else {
              for (let i = 0; i < remarksSplit.length; i++) {
                if (remarksSplit[i]) {
                  questions.push({
                    title: null,
                    value: remarksSplit[i],
                  })
                }
              }
            }
          }
        } catch (error) {}
      }

      if (this.showNutritionGoal) {
        const notes = this.getClientNotes()
        const nutritionGoal = notes && notes.nutrition_goal ? notes.nutrition_goal : null
        if (nutritionGoal) {
          questions.push({
            title: window.strings['goal'],
            value: nutritionGoal,
            color: 'warning',
          })
        }
      }

      if (questions.length) {
        this.accordion.push({
          id: 'questions',
          title: window.strings['questions'],
          data: questions,
        })
      }
    },
    getClientNotes: function () {
      let data = {}
      if (this.client) {
        try {
          const notes = JSON.parse(JSON.stringify(this.client.notes))
          data = JSON.parse(notes)
        } catch {}
      }
      return data
    },
    getTrain: function (id) {
      const self = this
      if (id) {
        Api.getTrains(
          {
            client_id: this.client.dbId,
            ids: [id],
            fast: 1,
          },
          function (response) {
            if (response.success && response.data && response.data[0]) {
              self.details.push(
                {
                  title: window.strings['training_name'],
                  value: response.data[0].name,
                },
                {
                  title: window.strings['training_day'],
                  value: response.data[0].date,
                },
              )
            }
          },
        )
      }
    },
    setBody: function () {
      const obj = {
        id: 'body',
        title: window.strings['body'],
        data: [],
      }

      for (const key in this.physicalEvaluation.body) {
        if (key === 'id') {
          continue
        }
        obj.data.push({
          title: window.strings[key] ? window.strings[key] : key,
          value: this.physicalEvaluation.body ? this.physicalEvaluation.body[key] : '',
        })
      }

      obj.data = obj.data.sort(function (a, b) {
        return a.title.localeCompare(b.title)
      })

      this.accordion.push(obj)
    },
    setPerimeters: function (fields) {
      const obj = {
        id: 'perimeters',
        title: window.strings['perimeters'],
        data: [],
      }

      if (fields) {
        fields.forEach((f) => {
          obj.data.push({
            id: f.id,
            title: f.title
              ? window.strings[f.title]
                ? window.strings[f.title]
                : f.title
              : window.strings[f.id]
                ? window.strings[f.id]
                : f.id,
            value: this.physicalEvaluation.perimeters ? this.physicalEvaluation.perimeters[f.id] : '',
          })
        })
      } else {
        for (const key in this.physicalEvaluation.perimeters) {
          if (key === 'id') {
            continue
          }
          obj.data.push({
            id: key,
            title: window.strings[key] ? window.strings[key] : key,
            value: this.physicalEvaluation.perimeters ? this.physicalEvaluation.perimeters[key] : '',
          })
        }
        obj.data = obj.data.sort(function (a, b) {
          return a.title.localeCompare(b.title)
        })
      }

      this.accordion.push(obj)
    },
    setMeasurements: function (steps) {
      try {
        if (this.physicalEvaluation.measurements) {
          const measurements = JSON.parse(this.physicalEvaluation.measurements)
          const data = []
          const step = steps.find(function (item) {
            return item.id === 'measurements'
          })

          for (let m = 0; m < measurements.length; m++) {
            const field = step
              ? step.fields.find(function (item) {
                  return item.id === measurements[m].id
                })
              : null
            let value = '-'
            if (measurements[m] && (measurements[m].answer || measurements[m].answer === 0)) {
              value = measurements[m].answer
            }
            if (measurements[m] && (measurements[m].value || measurements[m].value === 0)) {
              value = measurements[m].value
            }
            let title = measurements[m] ? measurements[m].title : measurements[m].id
            if (field && field.title) {
              title = field.title
            }

            if (this.matchMeasurementsPerimeters) {
              const perimetersAccordionIndex = this.accordion.findIndex(function (ac) {
                return ac.id === 'perimeters'
              })
              if (perimetersAccordionIndex > -1) {
                this.accordion[perimetersAccordionIndex].data.forEach(function (it) {
                  if (measurements[m].id === it.id) {
                    it.value = value
                  }
                })
                this.details.forEach(function (it) {
                  if (measurements[m].id === it.id) {
                    it.value = value
                  }
                })
              }
            } else {
              data.push({
                parent: measurements[m] ? measurements[m].parent : null,
                title: title,
                value: value,
              })
            }
          }

          if (data.length && !this.matchMeasurementsPerimeters) {
            this.accordion.push({
              id: 'measurements',
              title: window.strings['measurements'],
              data: data,
            })
          }
        }
      } catch (error) {}
    },
    addAccordions: function (accordions) {
      const self = this
      if (accordions) {
        let hasTrainingPlanAccordion = false
        let hasFoodPlanAccordion = false
        accordions.forEach(function (item) {
          if (item === 'training_plan') {
            hasTrainingPlanAccordion = true
          }
          if (item === 'food_plan') {
            hasFoodPlanAccordion = true
          }
          self.accordion.push({
            id: item,
            title: window.strings[item],
          })
        })
        if (hasTrainingPlanAccordion) {
          this.getTrainingPlan(true)
        }
        if (hasFoodPlanAccordion) {
          this.getFoodPlan(true)
        }
      }
    },
    changeAccordion: function (item) {
      if (item.id === 'food_plan' && this.foodPlan === null) {
        return this.getFoodPlan()
      }
      if (item.id === 'training_plan' && this.trainingPlan === null) {
        return this.getTrainingPlan()
      }
      if (item.id === 'calendar' && !this.calendarOpened) {
        this.calendarOpened = true
        return true
      }
    },
    getFoodPlan: function (init) {
      const self = this
      if (!init) {
        this.foodPlan = false
      }
      this.foodPlanLoading = true
      Api.getFoodPlan(
        {
          client_id: this.client.dbId,
          fields: init ? ['id', 'date', 'type'] : null,
        },
        function (response) {
          self.foodPlanLoading = false
          if (response.success && response.data[0]) {
            if (init) {
              self.foodPlanInfo = response.data[0]
            } else {
              self.foodPlan = response.data[0]
            }
          }
        },
      )
    },
    getTrainingPlan: function (init) {
      const self = this
      if (!init) {
        this.trainingPlan = false
      }
      this.trainingPlanLoading = true
      Api.getTrainingPlan(
        {
          client_id: this.client.dbId,
          fields: init ? ['id', 'start_date', 'end_date'] : null,
        },
        function (response) {
          self.trainingPlanLoading = false
          if (response.success && response.data[0]) {
            if (init) {
              self.trainingPlanInfo = response.data[0]
            } else {
              self.trainingPlan = response.data[0]
            }
          }
        },
      )
    },
    checkAccordionsOpened: function () {
      const self = this
      const config = this.user && this.user.configurations ? this.user.configurations : null
      const typeConfig =
        config && config.feedback && config.feedback.types
          ? config.feedback.types.find(function (it) {
              return it.type === self.physicalEvaluation.type
            })
          : null
      this.accordionsOpened = []
      if ((config && config.feedback && config.feedback.open_accordions) || (typeConfig && typeConfig.open_accordions)) {
        for (let i = 0; i < this.accordion.length; i++) {
          this.accordionsOpened.push(i)
        }
      }
      if ((config && config.feedback && config.feedback.open_accordions_ids) || (typeConfig && typeConfig.open_accordions_ids)) {
        const accordionsToOpen =
          typeConfig && typeConfig.open_accordions_ids
            ? typeConfig.open_accordions_ids
            : config && config.feedback && config.feedback.open_accordions_ids
              ? config.feedback.open_accordions_ids
              : []
        for (let i = 0; i < this.accordion.length; i++) {
          if (accordionsToOpen.indexOf(this.accordion[i].id) > -1) {
            this.accordionsOpened.push(i)
          }
        }
      }
    },
    getAssets: function (callback) {
      const self = this
      this.photos = []
      this.videos = []
      Api.getPhysicalEvaluationPhotos(
        {
          client_id: this.client.dbId,
          id: this.physicalEvaluation.id,
        },
        function (response) {
          if (response.success) {
            const videos = []
            const photos = []
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].photo) {
                if (response.data[i].type > 0) {
                  videos.push(response.data[i].photo)
                } else {
                  photos.push(response.data[i])
                }
              }
            }
            self.videos = videos
            self.handlePhysicalEvaluation(self, photos)
            self.getVideos()
          }
          if (callback) {
            callback()
          }
        },
      )
    },
    getPhotos: function (item) {
      const self = this
      item.loading = true
      Api.getPhysicalEvaluationPhotos(
        {
          client_id: this.client.dbId,
          id: item.id,
        },
        function (response) {
          const photos = []
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].photo) {
                if (response.data[i].type === 0) {
                  photos.push(response.data[i])
                }
              }
            }
          }
          self.handlePhysicalEvaluation(item, photos)
          item.loading = false
        },
      )
    },
    handlePhysicalEvaluation: function (item, photos) {
      if (this.showUpdateImage) {
        if (photos.length < this.totalPhotos) {
          for (let c = 0; c < this.totalPhotos; c++) {
            if (!photos[c]) {
              photos.push('')
            }
          }
        }
        item.photos = photos
      } else {
        item.photos = photos
      }
    },
    getVideos: function (ids, isDetails) {
      const self = this
      const videosIds = ids ? ids : this.videos

      for (let i = 0; i < videosIds.length; i++) {
        const videoId = parseInt(videosIds[i])
        StorageApi.getVideo(videoId, function (response) {
          if (response.success && response.data[0]) {
            if (isDetails) {
              for (let i = 0; i < self.details.length; i++) {
                if (self.details[i].type === 'video' && parseInt(self.details[i].value) === response.data[0].id) {
                  self.details[i].value = response.data[0].file
                  self.$set(self.details, i, self.details[i])
                  break
                }
              }
            } else {
              for (let v = 0; v < videosIds.length; v++) {
                if (parseInt(videosIds[v]) === response.data[0].id) {
                  videosIds[v] = response.data[0].file
                  self.$set(videosIds, v, videosIds[v])
                  break
                }
              }
            }
          }
        })
      }
    },
    getFeedbacksList: function (updated) {
      const self = this
      const list = []
      if (this.showOthersFeedbacks && this.physicalEvaluationsList && this.physicalEvaluationsList.length) {
        if (updated) {
          const index = this.feedbacksList.findIndex(function (item) {
            return self.physicalEvaluation.id === item.id
          })
          if (index > -1) {
            this.feedbacksList[index].weight = this.physicalEvaluation.body ? this.physicalEvaluation.body.weight : 0
            this.$set(this.feedbacksList, index, this.feedbacksList[index])
          }
        } else {
          this.physicalEvaluationsList.forEach(function (item) {
            const isCurrent = self.physicalEvaluation.id === item.id ? true : false
            list.push({
              id: item.id,
              date: item.date,
              name: item.id + ': ' + item.date + (isCurrent ? ' (' + window.strings['current'] + ')' : ''),
              photos: [],
              currentPhoto: 0,
              weight: item.body ? item.body.weight : 0,
              loading: isCurrent,
              current: isCurrent,
              disabled: isCurrent,
            })
          })
        }
      }
      if (!updated) {
        if (!this.othersFeedbacksFilter.value.length) {
          const feedbacksToShow = [this.physicalEvaluation.id]
          if (list && list[1]) {
            feedbacksToShow.push(list[1].id)
            this.getPhotos(list[1])
          }
          this.othersFeedbacksFilter.value = feedbacksToShow
        }
        this.feedbacksList = list
      }
    },
    setCurrentFeedbackPhotos: function () {
      if (this.showOthersFeedbacks && this.feedbacksList) {
        for (let i = 0; i < this.feedbacksList.length; i++) {
          if (this.feedbacksList[i].id === this.physicalEvaluation.id) {
            this.feedbacksList[i].photos = this.photos
            break
          }
        }
      }
    },
    updatePhotos: function (event) {
      const self = this
      if (event) {
        this.$isLoading(true)
        const file = event.srcElement.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result
          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              saveImage(e.target.result)
            } else {
              saveImage(resizeImage(image))
            }
          }
        }
        reader.onerror = function () {
          self.$isLoading(false)
          self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
        }
      }

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }

      function saveImage(image) {
        const payload = {
          client_id: self.client.dbId,
          pe_id: self.physicalEvaluation.id,
          assets: [],
        }
        const currentPhoto = self.getCurrentPhoto()
        self.photos.forEach(function (item, index) {
          const photoToSave = currentPhoto === index ? image : item.photo
          if (photoToSave) {
            payload.assets.push({
              pe_id: self.physicalEvaluation.id,
              photo: photoToSave,
            })
          }
        })
        self.$isLoading(true)
        Api.updatePhysicalEvaluationPhotos(payload, function (response) {
          if (response.success) {
            document.getElementById('phyeval-input-photos').value = ''
            self.getAssets(function () {
              self.$isLoading(false)
              self.setCurrentFeedbackPhotos()
            })
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    getCurrentPhoto: function () {
      this.currentPhotoIndex
      if (this.showOthersFeedbacks && this.feedbacksList.length) {
        const item = this.feedbacksList.find(function (f) {
          return f.current
        })
        if (item) {
          return item.currentPhoto
        }
      }
      return this.currentPhotoIndex
    },
    downloadImage: function (image) {
      const a = document.createElement('a')
      a.href = image
      a.download = 'image.png'
      a.click()
    },
    downloadVideo: function (video) {
      const a = document.createElement('a')
      a.href = video
      a.download = video.indexOf('video/quicktime') > -1 ? 'video.mov' : 'video.mp4'
      a.click()
    },
    sortFeedbackList: function (item, side) {
      const ids = JSON.parse(JSON.stringify(this.othersFeedbacksFilter.value))
      if (side === 'right') {
        const index = ids.indexOf(item.id)
        if (index > -1 && index < ids.length - 1) {
          const temp = ids[index]
          ids[index] = ids[index + 1]
          ids[index + 1] = temp
        }
      } else {
        const index = ids.indexOf(item.id)
        if (index > 0) {
          const temp = ids[index]
          ids[index] = ids[index - 1]
          ids[index - 1] = temp
        }
      }
      this.othersFeedbacksFilter.value = ids
    },
    openFeedbackMessage: function () {
      this.feedbackMessage = this.showSendFeedback && this.physicalEvaluation.feedback ? this.physicalEvaluation.feedback : ''
      this.dialogFeedbackMessage = true
    },
    sendFeedbackMessage: function () {
      const self = this
      this.$isLoading(true)
      this.dialogFeedbackMessage = false
      if (this.showSendFeedback) {
        Api.editPhysicalEvaluation(
          {
            client_id: this.client.dbId,
            id: this.physicalEvaluation.id,
            feedback_status: this.feedbackMessage ? 1 : 0,
            feedback: this.feedbackMessage,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.getPhysicalEvaluation()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        Api.sendFeedbackReply(
          {
            client_id: this.client.dbId,
            reply: this.feedbackMessage,
          },
          function (response) {
            self.$isLoading(false)
            if (!response.success) {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    validatePhysicalEvaluation: function () {
      const self = this
      const config = this.user && this.user.configurations ? this.user.configurations : null
      const validatedStatus = Utils.getPhysicalEvaluationStatus().find(function (s) {
        return s.status === 'validated'
      })
      this.$isLoading(true)
      Api.editPhysicalEvaluation(
        {
          client_id: this.client.dbId,
          id: this.physicalEvaluation.id,
          status: validatedStatus.value,
          validate: true,
          ignore_multiple_validations:
            config &&
            config.feedback &&
            config.feedback.ignore_multiple_validations &&
            (config.feedback.ignore_multiple_validations.all ||
              config.feedback.ignore_multiple_validations.types.includes(this.physicalEvaluation.type))
              ? true
              : false,
        },
        function (response) {
          if (response.success) {
            self.refreshClient(function () {
              self.$isLoading(false)
              if (self.openChatAfterValidation) {
                self.openClientChat()
              }
              if (self.openPaydayAfterValidation) {
                self.openPayday()
              }
              if (self.addFoodPlanOptionAfterValidation && self.isDefaultType && self.foodPlanInfo) {
                self.addFoodPlanOption()
              }
              if (self.updateFeedbackDateAfterValidation && self.feedbackRef) {
                return self.feedbackRef.openFeedbackDialog()
              }
              if (config && config.feedback && config.feedback.feedback_update_days) {
                return self.updateFeedbackDayAuto()
              }
              if (self.hasChatReply) {
                return self.openChatReply()
              }
              if (self.showSendFeedbackAfterValidation) {
                return self.getPhysicalEvaluation(true, () => {
                  if (self.physicalEvaluation.status !== 1) {
                    self.back()
                  }
                })
              }
              self.finish()
            })
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    addFoodPlanOption: function () {
      const self = this
      try {
        if (this.$refs.foodPlanComponent && this.$refs.foodPlanComponent[0]) {
          if (this.$refs.foodPlanComponent[0].autoAddIncludeRecipe) {
            const success = this.$refs.foodPlanComponent[0].autoAddIncludeRecipe()
            if (success) {
              this.goToAccordion('food_plan')
            }
          }
        } else {
          this.addFoodPlanOptionProcessing = true
          this.foodPlanComponentMountedCallback = function () {
            self.addFoodPlanOptionProcessing = false
            self.foodPlanComponentMountedCallback = null
            if (self.$refs.foodPlanComponent && self.$refs.foodPlanComponent[0]) {
              if (self.$refs.foodPlanComponent[0].autoAddIncludeRecipe) {
                const success = self.$refs.foodPlanComponent[0].autoAddIncludeRecipe()
                if (success) {
                  self.goToAccordion('food_plan')
                }
              }
            }
          }
          this.openAccordion('food_plan')
        }
      } catch {}
    },
    updateFeedbackDayAuto: function () {
      const self = this
      const config = this.user && this.user.configurations ? this.user.configurations : null
      this.$isLoading(true)
      Api.updateUser(
        {
          id: this.client.id,
          feedback_day:
            config && config.feedback && config.feedback.feedback_update_days
              ? moment().add(config.feedback.feedback_update_days, 'days').format('YYYY-MM-DD')
              : null,
          log_source: 'AUTO-VALIDATE',
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.client.feedback_day = response.data.feedback_day
            Utils.setStorage('client', self.client)
            self.finish()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openEditDetails: function () {
      this.dialogEditDetails = true
    },
    closeEditDetails: function () {
      this.dialogEditDetails = false
    },
    saveEditDetails: function () {
      const self = this
      const data = {
        client_id: this.client.dbId,
        id: this.physicalEvaluation.id,
      }

      this.$isLoading(true)

      for (let i = 0; i < this.detailsToEdit.length; i++) {
        const item = this.detailsToEdit[i]
        if (item.field) {
          if (!data[item.id]) {
            data[item.id] = {}
          }
          data[item.id][item.field] = parseFloat(item.value)
        } else {
          data[item.id] = item.value
        }
      }

      Api.editPhysicalEvaluation(data, function (response) {
        if (response.success) {
          self.closeEditDetails()
          self.getPhysicalEvaluation(true)
        } else {
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    deleteImage: function (image) {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deletePhysicalEvaluationPhotos(
            {
              client_id: this.client.dbId,
              pe_id: this.physicalEvaluation.id,
              ids: [image.id],
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                const index = self.photos.findIndex(function (item) {
                  return item.id === image.id
                })
                if (index > -1) {
                  if (self.showUpdateImage) {
                    self.photos[index] = ''
                    self.$set(self.photos, index, self.photos[index])
                  } else {
                    self.photos.splice(index, 1)
                  }
                }
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    changeToProgressStatus: function () {
      const progressingStatus = this.physicalEvaluationStatusDict.progressing
      if (progressingStatus) {
        this.$confirm(window.strings['are_you_sure_change_feedback_progress_status'], '', 'warning', Utils.getAlertOptions(true, true))
          .then(() => {
            this.$isLoading(true)
            Api.editPhysicalEvaluation(
              {
                client_id: this.client.dbId,
                id: this.physicalEvaluation.id,
                status: progressingStatus,
              },
              (response) => {
                this.$isLoading(false)
                if (response.success) {
                  this.getPhysicalEvaluation()
                } else {
                  this.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                }
              },
            )
          })
          .catch(() => {})
      }
    },
    openClientChat: function () {
      Utils.openClientChat({
        scope: this,
        client: this.client,
        save: true,
      })
    },
    openChatReply: function () {
      this.chatReplyMessage = ''
      this.dialogChatReply = true
    },
    closeChatReply: function () {
      this.dialogChatReply = false
      this.chatReplyMessage = ''
      this.finish()
    },
    sendChatReply: function () {
      const self = this

      if (!this.chatReplyMessage) {
        return true
      }

      self.$isLoading(true)

      if (!self.client.chat_id) {
        return self.createChatClient(function () {
          self.sendChatReply()
        })
      }

      getPtChatId(function (ptChatId) {
        getMessages(ptChatId, function (messages) {
          send(messages)
        })
      })

      function getPtChatId(callback) {
        Api.getUserWithDbId(
          {
            id: axios.defaults.ptId,
            fields: ['id', 'chat_id'],
          },
          function (response) {
            if (response.success) {
              callback(response.data[0].chat_id)
            } else {
              self.$isLoading(false)
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }

      function getMessages(ptChatId, callback) {
        ChatApi.getChatRoomsIds(
          {
            ids: [ptChatId],
          },
          function (resp) {
            if (resp.success) {
              const ptRooms = resp.data.map((a) => a.chat_id)
              ChatApi.getChatRoomsIds(
                {
                  ids: [self.client.chat_id],
                },
                function (response) {
                  if (response.success) {
                    const msgs = []
                    response.data.forEach(function (it) {
                      if (ptRooms.indexOf(it.chat_id) > -1) {
                        msgs.push({
                          employee_pt_id: self.user.pt_id,
                          employee_id: Math.abs(self.user.id),
                          chat_id: it.chat_id,
                          user_id: ptChatId,
                          type: 0,
                          message:
                            window.strings['reply_feedback_day'] + ' ' + self.physicalEvaluation.date + ':\n\n' + self.chatReplyMessage,
                        })
                      }
                    })
                    callback(msgs)
                  } else {
                    self.$isLoading(false)
                    self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                  }
                },
              )
            } else {
              self.$isLoading(false)
              self.$alert(resp.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }

      function send(messages) {
        ChatApi.sendChatMessages(messages, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeChatReply()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    createChatClient: async function (callback) {
      const self = this
      const userObj = JSON.parse(JSON.stringify(this.client))
      const getPasswordRes = await getPassword()
      if (getPasswordRes === 'error') {
        self.$isLoading(false)
        return self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
      }
      userObj.password = getPasswordRes

      const newChatClientId = await newUser()
      if (newChatClientId === 'error') {
        self.$isLoading(false)
        return self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
      }

      const newChatRes = await newChat()
      if (newChatRes === 'error') {
        self.$isLoading(false)
        return self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
      }

      const updateUserRes = await updateUser()
      if (updateUserRes === 'error') {
        self.$isLoading(false)
        return self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
      }

      if (callback) {
        callback()
      }

      function getPassword() {
        return new Promise((resolve) => {
          Api.decryptPassword(userObj.password, function (response) {
            if (response.success) {
              resolve(response.data.password)
            } else {
              resolve('error')
            }
          })
        })
      }

      function newUser() {
        return new Promise((resolve) => {
          ChatApi.getChatUserByEmail(userObj.email, function (response) {
            if (response.success) {
              if (response.data[0]) {
                resolve(response.data[0].id)
              } else {
                newUser()
              }
            } else {
              newUser()
            }
          })

          function newUser() {
            ChatApi.newChatUser(
              {
                token: userObj.chat_token ? userObj.chat_token : '',
                name: userObj.name,
                email: userObj.email,
                password: userObj.password,
              },
              function (response) {
                if (response.success) {
                  resolve(response.data.id)
                } else {
                  resolve('error')
                }
              },
            )
          }
        })
      }

      function newChat() {
        return new Promise((resolve) => {
          ChatApi.getChatRoomsIds(
            {
              ids: [newChatClientId],
            },
            function (response) {
              if (response.success) {
                if (response.data[0]) {
                  resolve(response.data[0])
                } else {
                  createNewChat()
                }
              } else {
                resolve('error')
              }
            },
          )

          function createNewChat() {
            Api.getUserWithDbId(
              {
                id: axios.defaults.ptId,
                fields: ['id', 'chat_id'],
              },
              function (response) {
                if (response.success) {
                  const ptUser = response.data[0]
                  if (ptUser.chat_id) {
                    ChatApi.newChat(
                      {
                        name: userObj.id + ' - ' + userObj.name,
                        type: 0,
                        users: [ptUser.chat_id, newChatClientId],
                      },
                      function (response) {
                        if (response.success) {
                          resolve(response.data)
                        } else {
                          resolve('error')
                        }
                      },
                    )
                  } else {
                    resolve('error')
                  }
                } else {
                  resolve('error')
                }
              },
            )
          }
        })
      }

      function updateUser() {
        return new Promise((resolve) => {
          Api.updateUser(
            {
              id: userObj.id,
              chat_id: newChatClientId,
            },
            function (response) {
              if (response.success) {
                self.client.chat_id = newChatClientId
                Utils.setStorage('client', self.client)
                resolve(response.data)
              } else {
                resolve('error')
              }
            },
          )
        })
      }
    },
    changeTypeTab: function () {
      if (this.showDiary && this.typesTabs[this.typeTabActive].status === 'diary' && !this.diaryTable.ready) {
        this.diaryTable.ready = true
        this.getDiaryPhysicalEvaluations()
      }
    },
    getDiaryPhysicalEvaluations: function () {
      const self = this
      const type = this.typesTabs.find(function (t) {
        return t.status === 'diary'
      })
      if (type) {
        this.diaryTable.loading = true
        if (this.showDiaryList) {
          this.getPhysicalEvaluations(type)
        } else {
          this.getDiaryConfig(type.id, function (config) {
            self.getPhysicalEvaluations(type, config)
          })
        }
      }
    },
    getDiaryConfig: function (type, callback) {
      const self = this
      Api.getFeedbackConfig(
        {
          type: type,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            callback(response.data)
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getPhysicalEvaluations: function (type, config) {
      const self = this
      Api.getPhysicalEvaluations(
        {
          id: self.client.dbId,
          orderAsc: false,
          type: type ? type.id : null,
          startDate: self.physicalEvaluation.date_next,
          endDate: self.physicalEvaluation.date,
        },
        function (response) {
          if (response.success) {
            self.drawDiaryTable(config, response.data)
            self.diaryTable.loading = false
          } else {
            self.diaryTable.loading = false
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    drawDiaryTable: function (config, data) {
      const body = []
      const headers = []
      if (config) {
        headers.push({
          id: 'date',
          text: window.strings['date'],
          value: 'field-date',
          parentId: null,
          width: 110,
          alert: false,
          isRemarks: false,
        })
        config.steps.forEach(function (step) {
          if (step.fields) {
            step.fields.forEach(function (field) {
              const text = field.dashboard
                ? window.strings[field.dashboard]
                  ? window.strings[field.dashboard]
                  : field.dashboard
                : window.strings[field.title]
                  ? window.strings[field.title]
                  : field.title
              const width = text.length * 10
              headers.push({
                id: field.id,
                text: text,
                value: 'field-' + field.id,
                align: 'center',
                parentId: field.parentId,
                width: width > 90 ? width : 100,
                alert: field.type === 'textarea' ? true : false,
                isRemarks: !field.parentId ? true : false,
              })
            })
          }
        })
        this.diaryTable.headers = headers
        if (data && data.length) {
          data.forEach(function (item) {
            const remarks = item.remarks ? JSON.parse(item.remarks) : []
            const row = {}
            headers.forEach(function (header) {
              let value = ''
              if (header.isRemarks) {
                const src = remarks.find(function (r) {
                  return r.id === header.id
                })
                value = src ? src.value : ''
              } else if (header.parentId) {
                value = item[header.parentId] ? item[header.parentId][header.id] : ''
              } else {
                value = item[header.id]
              }
              row[header.value] = value
            })
            body.push(row)
          })
        }
        this.diaryTable.body = body
      } else {
        headers.push({
          id: 'date',
          text: '',
          value: 'field-date',
          parentId: null,
          width: 110,
          alert: false,
          isRemarks: false,
        })
        this.diaryTable.headers = headers
        if (data && data.length) {
          data.forEach(function (item) {
            body.push({
              id: item.id,
              type: item.type,
              'field-date':
                window.strings['week'] + ' ' + window.strings['of'] + ' ' + item.date + ' ' + window.strings['to'] + ' ' + item.date_next,
            })
          })
          this.diaryTable.body = body
        }
      }
    },
    showInformation: function (message) {
      this.$alert(message, '', 'info', Utils.getAlertOptions())
    },
    trainingPlanDestroyValidation: function (callback) {
      if (
        this.$refs.trainingPlanComponent &&
        this.$refs.trainingPlanComponent[0] &&
        this.$refs.trainingPlanComponent[0].destroyValidation
      ) {
        return this.$refs.trainingPlanComponent[0].destroyValidation(function (success) {
          callback(success !== false ? true : false)
        })
      }
      return callback(true)
    },
    foodPlanDestroyValidation: function (callback) {
      if (this.$refs.foodPlanComponent && this.$refs.foodPlanComponent[0] && this.$refs.foodPlanComponent[0].destroyValidation) {
        return this.$refs.foodPlanComponent[0].destroyValidation(function (success) {
          callback(success !== false ? true : false)
        })
      }
      return callback(true)
    },
    deletePhysicalEvaluation: function () {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deletePhysicalEvaluation(
            {
              client_id: self.client.dbId,
              id: self.physicalEvaluation.id,
            },
            function (response) {
              if (response.success) {
                self.refreshClient(function () {
                  const ids = []
                  if (self.videos) {
                    self.videos.forEach(function (v) {
                      ids.push(v.id)
                    })
                  }
                  if (self.photos) {
                    self.photos.forEach(function (v) {
                      ids.push(v.id)
                    })
                  }
                  if (ids.length) {
                    Api.deletePhysicalEvaluationPhotos(
                      {
                        client_id: self.client.dbId,
                        pe_id: self.physicalEvaluation.id,
                        ids: ids,
                      },
                      function (response) {
                        self.$isLoading(false)
                        if (response.success) {
                          self.back()
                        } else {
                          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                        }
                      },
                    )
                  } else {
                    self.$isLoading(false)
                    self.back()
                  }
                })
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    finish: function () {
      if (this.validateKeepPage) {
        this.getPhysicalEvaluation(true)
      } else {
        this.back()
      }
    },
    openPhysicalEvaluation: function (item) {
      const self = this
      this.physicalEvaluationLoading = true
      this.physicalEvaluationOpened = item
      this.dialogPhysicalEvaluation = true
      setTimeout(function () {
        self.physicalEvaluationLoading = false
      }, 1000)
    },
    closePhysicalEvaluation: function () {
      this.dialogPhysicalEvaluation = false
      this.physicalEvaluationOpened = null
      this.physicalEvaluationLoading = false
    },
    openPayment: function () {
      const self = this
      if (this.paymentData) {
        this.dialogPayment = true
        return true
      }
      this.$isLoading(true)
      Api.getPayment(
        {
          db_id: this.client.dbId,
        },
        function (response) {
          self.$isLoading(false)
          const paymentData = response.data[0]
          if (response.success && paymentData) {
            paymentData.db_id = self.client.dbId
            self.paymentData = paymentData
            self.setPaymentMethods()
            self.dialogPayment = true
          } else {
            self.$alert(window.strings['payment_not_found'], '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    setPaymentMethods: function () {
      this.paymentData.setValue = (key, value) => {
        if (key) {
          this.paymentData[key] = value
        } else {
          this.paymentData = value
        }
      }
    },
    closePayment: function () {
      this.dialogPayment = false
    },
    openPayday: function () {
      if (this.client && !this.client.paym_sub && this.isInitialQuiz) {
        this.dialogPayday = true
        if (this.client.payday === window.strings['n_a']) {
          this.payday = ''
        } else {
          this.payday = this.client.payday
        }
      }
    },
    updatePayday: function (payday) {
      const self = this
      const data = {
        id: this.client.id,
        payday: payday,
        paym_check: '',
        paym_error: '',
      }
      this.dialogPayday = false
      Api.updateUser(data, function (response) {
        if (response.success) {
          self.client.payday = response.data.payday
          self.registerPayday()
          Utils.setStorage('client', self.client)
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    registerPayday: function () {
      const self = this
      if (this.user && this.user.configurations && this.user.configurations.payment && this.user.configurations.payment.payday_record) {
        Api.newPayment(
          {
            db_id: this.client.dbId,
            type: Utils.getPaymentTypeValue('manual'),
            cha_id: this.client.payday,
            promo_code: this.user.name + ' (' + this.user.id + ')',
            status: 1,
          },
          function (response) {
            if (!response.success) {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    activeFixedQuestions: function () {
      if (this.showFixedQuestions) {
        window.addEventListener('scroll', this.onScroll)
      }
    },
    onScroll: function () {
      try {
        const accordionFixedTop = document.getElementById('accordion-questions')
        if (accordionFixedTop) {
          const nextDiv = accordionFixedTop.nextElementSibling
          const nextDivTop = nextDiv.getBoundingClientRect().top + window.scrollY
          if (window.scrollY >= nextDivTop) {
            this.fixedQuestionsVisible = true
          } else {
            this.fixedQuestionsVisible = false
          }
        }
      } catch {}
    },
    removeEventListener: function () {
      if (this.showFixedQuestions) {
        window.removeEventListener('scroll', this.onScroll)
      }
    },
    goToAccordion: function (id) {
      const self = this
      const map = {
        food_plan: {
          component: 'foodPlanComponent',
          mountedCallback: 'foodPlanComponentMountedCallback',
        },
        training_plan: {
          component: 'trainingPlanComponent',
          mountedCallback: 'trainingPlanComponentMountedCallback',
        },
      }
      if (map[id] && (!this.$refs[map[id].component] || !this.$refs[map[id].component][0])) {
        this.$isLoading(true)
        this[map[id].mountedCallback] = function () {
          self[map[id].mountedCallback] = null
          const accordion = self.openAccordion(id)
          setTimeout(function () {
            if (accordion) {
              accordion.scrollIntoView({ behavior: 'auto', block: 'end' })
            }
            self.$isLoading(false)
          }, 600)
        }
      }
      const accordion = this.openAccordion(id)
      setTimeout(function () {
        if (accordion) {
          accordion.scrollIntoView({ behavior: 'auto', block: 'end' })
        }
      }, 250)
    },
    openAccordion: function (id) {
      try {
        const accordion = document.getElementById('accordion-' + id)
        if (accordion) {
          const panel = accordion.querySelector('.v-expansion-panel-header:not(.v-expansion-panel-header--active)')
          if (panel) {
            panel.click()
          }
          return accordion
        }
      } catch {}
      return null
    },
    refreshClient: function (callback) {
      const self = this
      if (this.refreshClientAfterValidation) {
        return Api.getUserWithId(
          {
            id: this.client.id,
          },
          function (response) {
            if (response.success && response.data && response.data[0]) {
              const client = Utils.encodeClient(response.data[0])
              client.photo = self.client.photo
              self.client = client
              Utils.setStorage('client', client)
            }
            callback()
          },
        )
      }
      if (this.client.employee1 && this.client.employee2) {
        return Api.getUserWithId(
          {
            id: this.client.id,
            fields: ['flags'],
          },
          function (response) {
            if (response.success && response.data && response.data[0]) {
              self.client.flags = response.data[0].flags
              Utils.setStorage('client', self.client)
            }
            callback()
          },
        )
      }
      callback()
    },
    resetClientData: function () {
      const self = this
      this.$confirm(window.strings['are_you_sure_reset_client_data'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          window.addEventListener('message', receiveData)
          self.resetClientDataProcessing = true

          function receiveData(event) {
            try {
              if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
                const data = JSON.parse(event.data)
                if (data && data.event === 'physical-evaluation-data') {
                  window.removeEventListener('message', receiveData)
                  self.resetClientDataProcessing = false
                  if (data.data && data.data !== 'error' && data.data.userDataToUpdate) {
                    data.data.userDataToUpdate.id = self.client.id
                    Api.updateUser(data.data.userDataToUpdate, function (response) {
                      self.$isLoading(false)
                      if (response.success) {
                        const client = Utils.encodeClient(response.data)
                        client.photo = self.client.photo
                        Utils.setStorage('client', client)
                        self.client = client
                        self.$alert(window.strings['data_saved_successfully'], '', 'success', Utils.getAlertOptions())
                      } else {
                        self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                      }
                    })
                  } else {
                    self.$isLoading(false)
                    self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
                  }
                }
              }
            } catch {
              window.removeEventListener('message', receiveData)
              self.$isLoading(false)
              self.$alert(window.strings['common_error'], '', 'warning', Utils.getAlertOptions())
            }
          }
        })
        .catch(() => {})
    },
    getPhysicalEvaluationsChartEventHandlers() {
      const self = this
      return [
        {
          event: 'created',
          fn(data) {
            try {
              const points = data.svg.querySelectorAll('.ct-point')
              points.svgElements.forEach((point, index) => {
                const item = self.physicalEvaluationsChart.data.series[0][index]
                if (item && item.highlightedColor) {
                  point.addClass('highlighted-point ' + item.highlightedColor)
                }
              })
            } catch {}
          },
        },
      ]
    },
    clientFeedbackMounted() {
      this.feedbackRef = this.$refs.feedback
    },
    clientFeedbackDestroy() {
      this.finish()
    },
  },
}
</script>
